var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-input", {
    ref: "inputRef",
    attrs: {
      placeholder: _vm.placeholder,
      icon: _vm.icon,
      disabled: _vm.disabled,
      value: _vm.defaultValue,
      type: "text"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }