/**
 * @description Mixin that includes common methods for resolve actions and event emision to view agnostic components
 */
export const formMixin = {
  data() {
    return {
      hasError: false,
      invalidFormError: false
    }
  },
  methods: {
    /**
     * @description Emits a form error event to be handled by the parent view
     * @returns {String, Object} Returns a vue event name and the error object to be handled
     */
    formHasError() {
      this.$emit('on-form-error', { hasError: this.hasError, invalidFormError: this.invalidFormError })
    },
    formModalCancel() {
      this.$emit('on-form-cancel')
    },
    /**
     * @description Method that makes validations after and before data submission
     * @param {Array} actions includes a set of actions (asynchronous methods) to be executed
     * @param {Object} payload includes the object to be proecessed by the action
     * @param {Object} hasRouting Includes properties for routing handling
     */
    validateBeforeSubmit([...actions], payload, hasRouting = { enabled: false, routeName: null }) {
      let response
      this.$emit('on-form-processing', { hasError: false, invalidFormError: false })
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          try {
            response = await actions[0](payload) // Asynchronous Action to be executed
            if (hasRouting.enabled) {
              this.$router.push({ name: hasRouting.routeName })
            } else {
              this.$emit('on-form-success', { hasSuccess: true })
            }
          } catch (error) {
            this.hasError = true
            this.invalidFormError = false
            this.formHasError()
          }
        } else {
          this.hasError = true
          this.invalidFormError = true
          this.formHasError()
        }
      })
    }
  }
}
