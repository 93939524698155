var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-field",
        [
          _c("b-datepicker", {
            attrs: {
              locale: _vm.locale,
              datepicker: { showWeekNumber: _vm.showWeekNumber },
              "max-date": _vm.maxDatetime,
              input: _vm.onChange(_vm.value),
              "nearby-selectable-month-days": _vm.nearbyselectable,
              rounded: "",
              placeholder: "Click to select...",
              icon: "calendar",
              size: "is-small"
            },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onTextEnter.apply(null, arguments)
              }
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }