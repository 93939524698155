<template>
    <b-input
      ref="inputRef"
      :placeholder=placeholder
      :icon=icon
      :disabled=disabled
      :value=defaultValue
      type="text"
    />
  </template>

<script>
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  props: {
    modelValue: Number,
    options: Object,
    placeholder: String,
    icon: String,
    disabled: Boolean,
    defaultValue: Number
  },
  setup(props) {
    const { inputRef } = useCurrencyInput({
      currency: 'EUR',
      locale: 'en-US',
      useGrouping: false,
      valueRange: {
        min: 0
      },
      ...props.options
    })

    return { inputRef }
  }
}
</script>
