<template>
  <div>
    <b-field>
      <!-- <b-button
          icon-left="times"
          size="is-small"
          @click="clearDate"
        ></b-button> -->
      <b-datepicker v-model="value" :locale="locale" :datepicker="{ showWeekNumber }" :max-date="maxDatetime"
        :input="onChange(value)" :nearby-selectable-month-days="nearbyselectable" rounded placeholder="Click to select..."
        icon="calendar" size="is-small" @keyup.enter.native="onTextEnter"></b-datepicker>
    </b-field>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'DatePicker',
  // eslint-disable-next-line vue/require-prop-types
  props: {
    nearbyselectable: Boolean,
    inputdate: String,
    value: String
  },
  data() {
    return {
      showWeekNumber: false,
      locale: 'en-us',
      maxDatetime: new Date(),
      content: this.value
    }
  },
  methods: {
    onChange(queryDate) {
      const date = moment(queryDate).format('DD/MM/YYYY')
      this.$emit('on-date-enter', { dateSelected: date })
      this.$emit('input', queryDate)
    }
  }
}
</script>
